@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .main {
    background-color: #030040;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2300327d' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%232c3155'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    animation: movebackground 60s linear infinite forwards;
  }

  .introframe {
    background-color: #091858;
    z-index: 200;
  }

  @keyframes movebackground {
    from {
      background-position: right;
    }
    to {
      background-position: left;
    }
  }

  .contactbox {
    background-color: #091858;
  }

  .contactbox-light {
    background-color: #112373;
  }

  .contactblock {
    transform-style: preserve-3d;
    background-color: #10163b;
  }

  .pin {
    transform: translateZ(-10px);
  }

  header {
    z-index: 250;
    background-color: #06113c;
  }

  .projects {
    background-color: #07154e;
    z-index: 220;
  }

  .triangle {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-left: 25px solid rgba(18, 39, 108);
    border-bottom: 8px solid transparent;
  }

  .triangledown {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 25px solid rgba(18, 39, 108);
  }

  #contactformcontainer {
    z-index: 260;
  }

  .graybgdiv {
    z-index: -10;
  }

  .xbutton {
    z-index: 100;
  }

  .norrland {
    z-index: 100;
    top: 131px;
    right: 18px;
    transform: scale(0.385);
  }

  .positioncontainer {
    z-index: 110;
    top: 235px;
    left: 87px;
  }

  .radarcontainer {
    color: #bfdbfe;

    transform: scale(0.8);
    animation: cycleopacity 1s ease-in-out alternate;
    animation-iteration-count: infinite;
  }

  .radar {
    color: #bfdbfe;
    z-index: 120;
    transform: scale(0.5);
  }

  .sidebar {
    z-index: 255;
    background-color: #06113c;
  }

  .hamburger {
    z-index: 260;
  }

  .hamburger > * {
    z-index: 256;
  }

  .introoverlay {
    z-index: 150;
  }

  .animatein {
    animation: scaleup 3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .animatein.norrland {
    animation: opacityup 3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .animatein.sweden {
    animation: swedenfade 3s ease-in-out;
    animation-fill-mode: forwards;
  }

  .animateout1 {
    animation: deflate 500ms ease-in-out;
    animation-fill-mode: forwards;
  }

  .glow {
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 16px -3px rgba(252, 252, 252, 0.1);
      color: #fcd34d;
    }
    to {
      box-shadow: 0 0 16px 6px rgba(252, 252, 252, 0.1);
      /*color: #fde68a;*/
      color: #fef3c7;
    }
  }

  @keyframes scaleup {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
      transform: scale(1.5);
    }
    42% {
    }
    50% {
      transform: scale(1.5);
    }
    80% {
      transform: scale(7);
      transform: scale(7) translate(3%, -5%);
    }
    100% {
      transform: scale(7) translate(3%, -5%);
    }
  }

  @keyframes cycleopacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.8;
    }
  }

  @keyframes opacityup {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    30% {
      color: #1d4ed8;
    }
    33% {
      color: #3b82f6;
    }
    36% {
      color: #2563eb;
    }
    39% {
      color: #3b82f6;
    }
    42% {
      color: #2563eb;
    }
    100% {
      opacity: 1;
      color: #2563eb;
    }
  }

  @keyframes swedenfade {
    0% {
      opacity: 1;
    }
    42% {
      opacity: 1;
      color: #1d4ed8;
    }
    100% {
      opacity: 0.8;
      color: #1e3a8a;
    }
  }

  @keyframes deflate {
    0% {
      transform: scale(1.5);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
}
